var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"main_box flex justify-center items-center"},[_c('div',{staticStyle:{"width":"1920px","min-width":"1920px","height":"1080px","margin":"0 auto"}},[_vm._m(0),_c('div',{staticClass:"bigimg"}),_c('div',{staticClass:"main"},[_c('div',{staticStyle:{"margin-top":"31px","font-size":"25px","font-weight":"600","padding-bottom":"20px","color":"#000","width":"100%","border-bottom":"1px solid #f3f4f6"}},[_vm._v(" 账号登录 ")]),_c('div',{staticClass:"flex justify-center zhongtai"}),_c('a-form',{ref:"formLogin",staticClass:"user-layout-login",attrs:{"id":"formLogin","form":_vm.form},on:{"submit":_vm.handleSubmit}},[(_vm.isLoginError)?_c('a-alert',{staticStyle:{"margin-bottom":"24px"},attrs:{"type":"error","showIcon":"","message":"用户名或密码错误"}}):_vm._e(),_c('div',{staticStyle:{"margin-top":"80px"}},[_c('img',{staticStyle:{"float":"left","margin-right":"10px","margin-top":"5px"},attrs:{"src":require("../../assets/login/acount.svg")}}),_c('span',{staticClass:"login_span"},[_vm._v("账号")])]),_c('a-form-item',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'username',
              {
                rules: [
                  { required: true, message: '请输入用户名' },
                  { validator: _vm.handleUsernameOrEmail },
                ],
                validateTrigger: 'change',
                
              },
            ]),expression:"[\n              'username',\n              {\n                rules: [\n                  { required: true, message: '请输入用户名' },\n                  { validator: handleUsernameOrEmail },\n                ],\n                validateTrigger: 'change',\n                \n              },\n            ]"}],staticClass:"myinput",attrs:{"size":"large","type":"text","placeholder":""}},[_c('a-icon',{style:({ color: 'rgba(0,0,0,.25)' }),attrs:{"slot":"prefix","type":"user"},slot:"prefix"})],1)],1),_c('div',{staticStyle:{"margin-top":"-10px"}},[_c('img',{staticStyle:{"float":"left","margin-right":"10px","margin-top":"5px"},attrs:{"src":require("../../assets/login/pass.svg")}}),_c('span',{staticClass:"login_span"},[_vm._v("密码")])]),_c('a-form-item',[_c('a-input-password',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'password',
              {
                rules: [{ required: true, message: '请输入密码' }],
                validateTrigger: 'blur',
              },
            ]),expression:"[\n              'password',\n              {\n                rules: [{ required: true, message: '请输入密码' }],\n                validateTrigger: 'blur',\n              },\n            ]"}],staticClass:"myinput",attrs:{"size":"large","placeholder":"密码"}},[_c('a-icon',{style:({ color: 'rgba(0,0,0,.25)' }),attrs:{"slot":"prefix","type":"lock"},slot:"prefix"})],1)],1),_c('a-form-item',{staticStyle:{"margin-top":"84px"}},[_c('a-button',{staticClass:"login-button",attrs:{"size":"large","type":"","htmlType":"submit","loading":_vm.state.loginBtn,"disabled":_vm.state.loginBtn}},[_vm._v("登录")])],1)],1),(_vm.requiredTwoStepCaptcha)?_c('two-step-captcha',{attrs:{"visible":_vm.stepCaptchaVisible},on:{"success":_vm.stepCaptchaSuccess,"cancel":_vm.stepCaptchaCancel}}):_vm._e()],1),_c('div',{staticClass:"right_all"},[_vm._v(" ©2016-2022望墨科技(武汉)有限公司 All Rights Reserved ")])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"english",staticStyle:{"padding-top":"40px"}},[_c('p',{staticStyle:{"color":"#284CEA","font-size":"48px","margin-bottom":"0px","margin-left":"32px"}},[_vm._v("欢迎使用")]),_c('p',{staticStyle:{"color":"#000","font-size":"29px","margin-left":"32px"}},[_vm._v("智慧试衣订单服务执行中台(独立控制版本-管理端)")])])
}]

export { render, staticRenderFns }