<template>
  <div class="main_box flex justify-center items-center">
  <div style='width:1920px;min-width:1920px;height:1080px;margin:0 auto;'>
    <div style='padding-top:40px;' class='english'>
      <p style='color:#284CEA;font-size:48px;margin-bottom:0px;margin-left:32px;' >欢迎使用</p>
      <p style='color:#000;font-size:29px;margin-left:32px;'>智慧试衣订单服务执行中台(独立控制版本-管理端)</p>
    </div>
    <div class='bigimg'>
    </div>
    <div class="main">
      <div style="margin-top:31px;font-size:25px;font-weight:600;
      padding-bottom:20px; color:#000;width:100%;border-bottom:1px solid #f3f4f6">
        账号登录
      </div>
        <div class="flex justify-center zhongtai ">
      
      </div>
      <a-form
        id="formLogin"
        class="user-layout-login"
        ref="formLogin"
        :form="form"
        @submit="handleSubmit"
      >
        <a-alert
          v-if="isLoginError"
          type="error"
          showIcon
          style="margin-bottom: 24px"
          message="用户名或密码错误"
        />
        <div style='margin-top:80px;'>
          <img style='float:left;margin-right:10px;margin-top:5px;' src='../../assets/login/acount.svg'>
          <span class='login_span'>账号</span>
        </div>
        <a-form-item >
          <a-input class='myinput' 
            size="large"
            type="text"
            placeholder=""
            v-decorator="[
              'username',
              {
                rules: [
                  { required: true, message: '请输入用户名' },
                  { validator: handleUsernameOrEmail },
                ],
                validateTrigger: 'change',
                
              },
            ]"
          >
            <a-icon
              slot="prefix"
              type="user"
              :style="{ color: 'rgba(0,0,0,.25)' }"
            />
          </a-input>
        </a-form-item>
        <div style='margin-top:-10px;'>
          <img style='float:left;margin-right:10px;margin-top:5px;' src='../../assets/login/pass.svg'>
          <span class='login_span'>密码</span>
        </div>
        <a-form-item>
          <a-input-password  class='myinput'
            size="large"
            placeholder="密码"
            v-decorator="[
              'password',
              {
                rules: [{ required: true, message: '请输入密码' }],
                validateTrigger: 'blur',
              },
            ]"
          >
            <a-icon
              slot="prefix"
              type="lock"
              :style="{ color: 'rgba(0,0,0,.25)' }"
            />
          </a-input-password>
        </a-form-item>

        <a-form-item style="margin-top: 84px">
          <a-button
            size="large"
            type=""
            htmlType="submit"
            class="login-button"
            :loading="state.loginBtn"
            :disabled="state.loginBtn"
            >登录</a-button
          >
        </a-form-item>

        <!-- <div class="user-login-other">
          <span>其他登陆方式</span>
          <a>
            <a-icon class="item-icon" type="alipay-circle"></a-icon>
          </a>
          <a>
            <a-icon class="item-icon" type="taobao-circle"></a-icon>
          </a>
          <a>
            <a-icon class="item-icon" type="weibo-circle"></a-icon>
          </a>
          <router-link class="register" :to="{ name: 'register' }">注册用户</router-link>
        </div> -->
      </a-form>

      <two-step-captcha
        v-if="requiredTwoStepCaptcha"
        :visible="stepCaptchaVisible"
        @success="stepCaptchaSuccess"
        @cancel="stepCaptchaCancel"
      ></two-step-captcha>
    </div>

    <div class="right_all">
				©2016-2022望墨科技(武汉)有限公司 All Rights Reserved
		</div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { timeFix } from "@/utils/util";
export default {
  data() {
    return {
      loginBtn: false,
      // login type: 0 email, 1 username, 2 telephone
      loginType: 0,
      isLoginError: false,
      requiredTwoStepCaptcha: false,
      stepCaptchaVisible: false,
      form: this.$form.createForm(this),
      state: {
        time: 60,
        loginBtn: false,
        // login type: 0 email, 1 username, 2 telephone
        loginType: 0,
        smsSendBtn: false,
      },
    };
  },
  created() {

  },
  methods: {
    ...mapActions(["Login"]),
    // handler
    handleUsernameOrEmail(rule, value, callback) {
      const { state } = this;
      const regex = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+((\.[a-zA-Z0-9_-]{2,3}){1,2})$/;
      if (regex.test(value)) {
        state.loginType = 0;
      } else {
        state.loginType = 1;
      }
      callback();
    },
    handleSubmit(e) {
      e.preventDefault();
      const {
        form: { validateFields },
        state,
        Login,
      } = this;
      state.loginBtn = true;
      const validateFieldsKey = ["username", "password"]
      validateFields(validateFieldsKey, { force: true }, (err, values) => {

        if (!err) {

          console.log('start')
        console.log(err)

        console.log(values)
          Login({
            username:values.username,
            password:values.password
          })
            .then((res) => this.loginSuccess(res))
            .catch((err) => this.requestFailed(err))
            .finally(() => {
              state.loginBtn = false;
            });
        } else {
          console.log(err)
          setTimeout(() => {
            state.loginBtn = false;
          }, 600);
        }
      });
    },
    // getCaptcha(e) {
    //   e.preventDefault();
    //   const {
    //     form: { validateFields },
    //     state,
    //   } = this;
    //   validateFields(["mobile"], { force: true }, (err, values) => {
    //     if (!err) {
    //       state.smsSendBtn = true;
    //       const interval = window.setInterval(() => {
    //         if (state.time-- <= 0) {
    //           state.time = 60;
    //           state.smsSendBtn = false;
    //           window.clearInterval(interval);
    //         }
    //       }, 1000);
    //       const hide = this.$message.loading("验证码发送中..", 0);
    //       getSmsCaptcha({ mobile: values.mobile })
    //         .then((res) => {
    //           setTimeout(hide, 2500);
    //           this.$notification["success"]({
    //             message: "提示",
    //             description:
    //               "验证码获取成功，您的验证码为：" + res.result.captcha,
    //             duration: 8,
    //           });
    //         })
    //         .catch((err) => {
    //           setTimeout(hide, 1);
    //           clearInterval(interval);
    //           state.time = 60;
    //           state.smsSendBtn = false;
    //           this.requestFailed(err);
    //         });
    //     }
    //   });
    // },
    stepCaptchaSuccess() {
      this.loginSuccess();
    },
    stepCaptchaCancel() {
      this.Logout().then(() => {
        this.loginBtn = false;
        this.stepCaptchaVisible = false;
      });
    },
    loginSuccess(res) {
      this.$router.push({ path: "/home" });
      // 延迟 1 秒显示欢迎信息
      setTimeout(() => {
        this.$notification.success({
          message: "欢迎",
          description: `${timeFix()}，欢迎回来`,
        });
      }, 300);
      this.isLoginError = false;
    },
    requestFailed(err) {
      this.isLoginError = true;
      this.$notification["error"]({
        message: "错误",
        description:
          ((err.response || {}).data || {}).message ||
          "请求出现错误，请稍后再试",
        duration: 4,
      });
    },
  },
};
</script>

<style lang="less" scoped>
.myinput{margin-top:30px;}
.login_span{font-size:20px;color:#000;}
.english{
      margin:140px 0px 0px 90px;width:737px; height:153px;
    background: #f0f2f5 url(../../assets/english.png) no-repeat 100%;
    background-size: 100%;
}
.bigimg{
    margin:81px 0px 0px 193px;width:657px; height:482px;
    background: #f0f2f5 url(../../assets/table.png) no-repeat 100%;
    background-size: 100%;float:left;
}
.main_box {
  width: 100%;
  height: 100%;
  background: #f0f2f5 url(../../assets/indexbg.png) no-repeat 100%;
  background-size: 100%;
}
.main {
  min-width: 260px;padding:49px;
  width: 600px;height:800px;
  float:right;margin:-150px 331px 0px 0px;background-color:#fff;border-radius:30px;
}
.zhongtai{font-size:1.2rem;color:#294DEA;line-height:3rem;margin-top:-1rem}
.right_all{text-align: center;
    font-size:1rem;
    position: fixed;
    width: 100%;
    color: #555;
    bottom: 11px;}
.user-layout-login {
  label {
    font-size: 14px;
  }
  .getCaptcha {
    display: block;
    width: 100%;
    height: 40px;
  }
  .forge-password {
    font-size: 14px;
  }
  button.login-button {
    padding: 0 15px;border-radius:80px;
    font-size: 23px;color:#fff;
    height: 80px;
    width: 100%;background-image: linear-gradient(#29CAFF, #294DEB);
  }
  .user-login-other {
    text-align: left;
    margin-top: 24px;
    line-height: 22px;
    .item-icon {
      font-size: 24px;
      color: rgba(0, 0, 0, 0.2);
      margin-left: 16px;
      vertical-align: middle;
      cursor: pointer;
      transition: color 0.3s;
      &:hover {
        color: #1890ff;
      }
    }
    .register {
      float: right;
    }
  }
}
</style>
